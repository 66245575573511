<template>
  <div>
    <!--Begin::LIST TEAMS-->
    <KTPortlet>
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <ShowTeams
          :key="teamsKey"
          @reset="teamsKey += 1"
          table-id="teams"
        >
          <template slot="title">{{ $t("TEAMS.SUB_TITLE") }}</template>
        </ShowTeams>
      </template>
    </KTPortlet>
    <!--End::LIST TEAMS-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ShowTeams from "@/views/pages/teams/ShowTeams.vue";

export default {
  name: "Team",
  components: {
    KTPortlet,
    ShowTeams,
  },
  data() {
    return {
      teamsKey: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("TEAMS.TITLE") },
    ]);
  },
  methods: {},
};
</script>
